<template>
  <div>
    <div v-if="bills === null && processing">
      <template v-for="index in 10">
        <v-skeleton-loader
          ref="skeleton"
          type="list-item-avatar-two-line"
          class="mx-auto"
          :key="index"
          tile
        />
      </template>
    </div>
    <div v-else-if="bills !== null && bills.length > 0 && !processing">
      <v-list tile>
        <template v-for="(bill, index) in bills">
          <BillingListItem
            :key="bill.uuid"
            :bill="bill"
          />
          <v-divider
            :key="index"
            inset
          />
        </template>
      </v-list>
    </div>
    <v-card
      v-else
      tile
    >
      <v-card-title>
        {{ $t('noBills') }}
      </v-card-title>
      <v-card-subtitle>
        {{ $t('yourOrganizationHasNoBillsFromUsYet') }}
      </v-card-subtitle>
    </v-card>
  </div>
</template>

<script>

  import BillingListItem from "./BillingListItem"

  export default {
    name: 'BillingList',
    components: {
      BillingListItem
    },
    props: {
      bills: {
        type: Array, 
        default: () => []
      },
      processing: {
        type: Boolean, 
      }
    },
    data: () => ({
      startIndex: 0,
      isLoadingMore: false,
    })
  }
</script>
