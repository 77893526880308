<template>
  <v-list-item
    two-line
    :key="bill.uuid"
  >
    <v-list-item-avatar>
      <v-icon
        v-if="bill.balance.amount === null || bill.balance.amount === 0"
        size="32"
        color="green"
      >
        mdi-check-circle
      </v-icon>
      <v-icon
        v-else
        size="32"
        color="orange"
      >
        mdi-alert-circle
      </v-icon>
    </v-list-item-avatar>
    <v-list-item-content>
      <v-list-item-title>{{ formattedAmount }}</v-list-item-title>
      <v-list-item-subtitle>{{ bill.created | date }}</v-list-item-subtitle>
    </v-list-item-content>
    <v-list-item-action v-if="bill.balance.amount !== null && bill.balance.amount > 0">
      <v-btn
        color="success"
        link
        :href="'https://pay.salescloud.is/?uuid=' + bill.uuid + '&channel=5ee17dfa-3e39-4ed0-bbe6-73810c7c0b1a'"
      >
        {{ $t('payInvoice') }}
      </v-btn>
    </v-list-item-action>
    <v-list-item-action>
      <v-btn
        target="_blank"
        link
        :href="documentLink"
        icon
      >
        <v-icon>mdi-download</v-icon>
      </v-btn>
    </v-list-item-action>
  </v-list-item>
</template>

<script>
  export default {
    name: 'BookingListItem',
    props: {
      bill: {
        type: Object, 
        default: () => {}
      }
    },
    data: () => ({

    }),
    methods: {

    },
    computed: {
      formattedAmount() {
        if(typeof this.bill !== 'undefined' && this.bill !== null) {
          return this.$store.getters.formatAmountByCurrency(this.bill.totalAmount)
        }
        return ''
      },
      documentLink() {
        return 'https://service-document.salescloud.is?uuid=' + this.bill.uuid + '&type=invoice'
      }
    }
  }
</script>
