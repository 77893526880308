<template>
  <v-container>
    <v-card>
      <v-card-title>{{ $t('invoices') }}</v-card-title>
      <v-card-subtitle>{{ $t('allCurrentInvoices') }}</v-card-subtitle>
      <BillingList
        :bills="bills"
        :processing="bills === null"
      />
    </v-card>
  </v-container>
</template>

<script>
import BillingList from "@/components/billing/BillingList"

export default {
    name: 'BillingInvoices',
    components: {
        BillingList
    },
    data: () => ({
      processing: false,
    }),
    computed: {
      bills: function() {

        const bills = this.$store.state.bills

        if(bills === null) {
          return []
        }

        return bills.filter(bill => bill.state === 'pending' || bill.state === 'billing_pending_payment').sort(function (a, b) {

          if (a.created > b.created) {
            return -1
          }

          if (b.created > a.created) {
            return 1
          }

          return 0
        })
      }
    }
}
</script>